export const CommonAPIConstant = {
    // Lookup
    lookup: `/lookup/`,
    app: `/security/modules`,
    assignedSchools: `/security/assigned-schools`,
    classrooms: `/school/classrooms/dropdown?getSameKeys=true`,
    defaultClassroom: `/school/courses/default`,
    instituteType: `/school/institute-type`,
    academicYears: `/academics/academic-year-setup/dropdown`,
    examination: `/academics/exams`,
    role: `/security/roles`,
    feeHeads: `/finance/fee-setup/heads`,
    outstandingFeeHeads: `/finance/fees/outstandingFeeHeads`,
    examType: `/academics/exams/examTypes`,
    category: `/finance/otherIncomeAndExpense/categories`,
    feeHeadsForPayment: `/finance/fees/feeHeadsForPayment`,
    datatableSetup: `/datatable/setup`,
    skills: `/academics/assessments/skills`,
    securityGroups: `/security/groups`,
    feeHeadsDropdown: `/finance/fee-setup/heads/dropdown`,
    lookupSetup: `/lookup/default-setup`,
    createDefaultCourses: `/school/courses/create/from-default-setup`,
    otherIncomeExpenseCategory: `/finance/otherIncomeAndExpense/categories`,
    updateDocumentTemplate: `/documents/templates`,
    userAccess: `https://spectrum.myakshar.com/auth/user-access`,
    classroomsByTemplate: `/academics/reportCard/get-classroomids`,


};
