import { Injectable } from "@angular/core";
import { HttpRequest, HttpInterceptor, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, tap } from 'rxjs/operators';
import { Router } from "@angular/router";
import { MessageService } from '../service';
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from "projects/public/src/environments/environment";

@Injectable({
    providedIn: "root"
})
export class ResponseInterceptor implements HttpInterceptor {

    constructor(private router: Router, private spinner: NgxSpinnerService, private messageService: MessageService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(req).pipe(
            catchError((err: any) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status === 406) {
                        this.spinner.hide();
                    } else if (err.status === 401) {
                        this.router.navigate(['/login']);
                    } else if (err.status === 408) {
                        this.handle408();
                    } else if (err.status === 500) {
                        // this.messageService.error(500, 'Error', 'Please contact technical support team.')
                    } else if (err.status === 432) {
                        this.handle432();
                    }
                }
                return throwError(err);
            })
        );
    }

    handle432 = () => {
        localStorage.clear();
        sessionStorage.clear();
        this.router.navigate(['/login']);
        this.messageService.error(432, 'Error', 'Your session has been expired. Please login again.')
    }

    handle408 = () => {
        if (window.location.href.includes('https://pph.spectrum.myakshar.com') || window.location.href.includes('https://pph.spectrum.myakshar.com')) {
            sessionStorage.clear();
            window.location.href = environment.loginAPIPath + '/login?redirectURL=' + encodeURIComponent(window.location.href);
        } else {
            localStorage.clear();
            sessionStorage.clear();
            this.router.navigate(['/login']);
            this.messageService.error(408, 'Error', 'Your session has been expired. Please login again.')
        }
    }
}
