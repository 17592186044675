import { environment } from "../../../environments/environment";

const apiPath = environment.apiPath;

export const APIConstant = {
    basePath: apiPath,
    events: `${apiPath}/calendar/events`,
    eventsByDate: `${apiPath}/calendar/events/by-date`,
    upcomingEvents: `${apiPath}/calendar/events/upcoming`,
    upcomingExams: `${apiPath}/academics/exams/schedule/upcoming`,
    examSchedule: `${apiPath}/academics/exams/schedule`,
    examScheduleByDate: `${apiPath}/academics/exams/schedule/by-date`,
    weekOffs: `${apiPath}/calendar/weekoff`,
    importHolidays: `${apiPath}/calendar/events/list`,
    eventIndicators: `${apiPath}/calendar/events/indicators`
};

export const PublicAPI = [];
