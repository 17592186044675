import {Component, Input, OnInit, OnChanges, SimpleChanges, ChangeDetectorRef, Injector} from '@angular/core';
import { AppService, UserAuthService } from '../../service';
import { Apps, AssignedSchool } from 'models';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SchoolChangePopupComponent } from '../school-change-popup/school-change-popup.component';
import { UploadService } from '../../service/upload.service';
import {CommonConstant} from "../../constants";
import {Images} from "@school-core";
@Component({
    selector: 'top-header-bar',
    templateUrl: './top-header-bar.component.html',
    styleUrls: ['top-header-bar.component.scss']
})

export class TopHeaderBarComponent implements OnInit, OnChanges {

    @Input() apps: Apps[];
    @Input() title: string;
    @Input() updatedEmployeeName: string;

    maxIntervalTimeCount = 3;
    initIntervalTimeCount = 1;

    schoolName: any;
    app: Apps;
    username: string;
    showMenu: boolean;
    modalRef: BsModalRef;
    school: AssignedSchool;
    logoUrl: any;
    employeeImage: any;
    employeeName: any;
    headerItems: any;
    modalClass: any;

    constructor(
        private accountService: UserAuthService,
        private modalService: BsModalService,
        private appService: AppService,
        private uploadService: UploadService,
        private userService: UserAuthService,
        private cd: ChangeDetectorRef,
    ) {


        if(window.sessionStorage.getItem(CommonConstant.appName) === 'SmartParent') {
            this.headerItems =   [{
                name: 'logout',
                icon: 'fa fa-sign-out mr-1',
                class: 'dropdown-item cursor-pointer',
                onTrigger: () => {
                    this.logout();
                }
            }];

        } else {
            this.headerItems =   [{
                name: 'My Profile',
                icon: 'fa fa-user mr-1',
                class: 'dropdown-item cursor-pointer',
                onTrigger: () => {
                    const item = {
                        route: 'https://myprofile.spectrum.myakshar.com',
                    }
                    this.redirect(item as any);
                }
            }, {
                name: 'logout',
                icon: 'fa fa-sign-out mr-1',
                class: 'dropdown-item cursor-pointer',
                onTrigger: () => {
                    this.logout();
                }
            }];
        this.modalClass = '';
        }

    }

    ngOnInit() {
        this.getCurrentSchool();
        
        const appName = window.sessionStorage.getItem(CommonConstant.appName);
        const token = window.sessionStorage.getItem(CommonConstant.token);

        if(appName === 'SmartParent') {
            this.employeeName = this.userService.getStudentName();
            this.getStudentLogo();
            // this.schoolName = window.sessionStorage.getItem('schoolName');
           this.schoolName = this.userService.getSchoolName();
            if(this.schoolName == null || this.schoolName == undefined) {
                this.schoolName = window.sessionStorage.getItem('schoolName');
            }


        }else if(!token){
            ++this.initIntervalTimeCount;

            if(this.initIntervalTimeCount<this.maxIntervalTimeCount){
                setTimeout(() => {
                    this.ngOnInit();
                    this.getAppData();
                }, 500);
            }
            return null;
        } else {
            this.employeeName = this.userService.getEmployeeName();
            this.getEmployeeLogo();
            this.schoolName = this.userService.getSchoolName();

        }

        if(!token){
            this.ngOnInit();
            return;
        }
        this.getSchoolLogo();
        this.cd.detectChanges();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['apps']?.currentValue && (this.apps || []).length > 0) {
            this.apps = this.apps.filter(app => app.moduleName !== 'MyProfile');
            this.apps.find(app => {
                if (app.moduleName === 'School') {
                    return app.moduleName = 'Institute';
                }
            })
            const app = this.apps.find(x => window.location.origin === x.route);
            if (app) {
                this.app = app;
            }
    //        console.log(`Apps: ${JSON.stringify(this.apps)}`);
        }
    }

    private getCurrentSchool() {
        this.appService.assignedSchoolList().subscribe(result => {
            const orgCode = this.accountService.getOrgCodes();
            if (result) {
                this.school = result.find(x => x.orgCodes === orgCode);
            }
        })
    }

    change() {

       if(window.sessionStorage.getItem(CommonConstant.userName)) {
           this.modalClass = 'modal-lg';
       } else {
           this.modalClass = 'modal-md';
       }

        this.modalRef = this.modalService.show(SchoolChangePopupComponent,{  class: this.modalClass } );
    }

    logout() {
        this.accountService.logout();
        window.location.href = '//spectrum.myakshar.com';
    }

    redirect(item: Apps) {

        if(window.sessionStorage.getItem(CommonConstant.appName) === 'SmartParent') {
            window.location.href = `${item.route}/validate?token=${window.sessionStorage.getItem(CommonConstant.token)}&code=${this.accountService.getOrgCodes()}&studentProfileId=${window.sessionStorage.getItem(CommonConstant.studentProfileId)}&studentName=${window.sessionStorage.getItem(CommonConstant.studentName)}&schoolName=${window.sessionStorage.getItem(CommonConstant.schoolName)}&appName=${window.sessionStorage.getItem(CommonConstant.appName)}&id=${window.sessionStorage.getItem(CommonConstant.studentProfileId)}&userName=${window.sessionStorage.getItem(CommonConstant.userName)}`;

        } else {
            window.location.href = `${item.route}/validate?token=${this.accountService.getToken()}&code=${this.accountService.getOrgCodes()}&appName=${window.sessionStorage.getItem(CommonConstant.appName)}&employeeId=${this.accountService.getEmployeeId()}&employeeName=${this.accountService.getEmployeeName()}&schoolName=${window.sessionStorage.getItem(CommonConstant.school)}&userName=${window.sessionStorage.getItem(CommonConstant.userName)}`;
        }
    }

    getSchoolLogo() {
        const params = {
            module: 'School',
            fileName: 'logo',
            contentType: 'image/jpeg'
        };
        this.uploadService.getDocument(params).subscribe(res => {
            this.logoUrl = res.URL;
            console.log(`School Image: ${this.logoUrl}`);
        },err=>{

        });
    }

    getEmployeeLogo() {
        const params = {
            module: 'Employees',
            fileName: sessionStorage.getItem("employeeId")
        };
        this.uploadService.getDocument(params).subscribe(res => {
            this.employeeImage = res.URL;
        });
    }
    getStudentLogo() {
        const params = {
            module: 'Students',
            fileName: this.userService.getStudentProfileId(),
            contentType: 'image/jpeg'
        };

        this.uploadService.getDocument(params).subscribe(res => {
            this.employeeImage = res.URL;
        });
    }

    private getAppData() {
        this.appService.get().subscribe((result) => {
            this.apps = result;
        });
    }
}
