export enum LookupCategory {
    Author = "Author",
    Booktype = "Booktype",
    Class = "Class",
    Degree = "Degree",
    Department = "EMP_DEPARTMENT",
    Designation = "EMP_DESIGNATION",
    Leave = "LEAVE_TYPE",
    Language = "Language",
    MemberType = "MemberType",
    Publisher = "Publisher",
    Section = "Section",
    Subject = "Subject",
    Subscription = "Subscription",
    Supplier = "Supplier",
    SubscriptionPlan = "SubrPlan",
    PlanFeature = "PlanFeatures",
    Employee_Status = "EMP_STATUS",
    BLOOD_GROUP = "BLOOD_GROUP",
    GENDER = "GENDER",
    EMP_DEPARTMENT = "EMP_DEPARTMENT",
    CASTE = "CASTE",
    RELIGION = "RELIGION",
    BOARDING_TYPE = "BOARDING_TYPE",
    SPEAKING_LANGUAGES = "SPEAKING_LANGUAGES",
    ADMISSION_CATEGORY = "ADMISSION_CATEGORY",
    NATIONALITY = "NATIONALITY",
    BoardingType = "BOARDING_TYPE",
    Languages = "SPEAKING_LANGUAGES",
    NAME_PREFIX = "NAME_PREFIX",
    EducationBoard = "EDUCATION_BOARD",
    FEE_CATEGORY = "FEE_CATEGORY",
    EXAM_TYPE = "EXAM_TYPE",
    ENQUIRY_STATUS = "ENQUIRY_STATUS",
    STUDENT_HOUSE = "STUDENT_HOUSE",
    SUPPLIER = "SUPPLIER",
    PAYMENT_METHOD = 'PAYMENT_METHOD',
    ADMITTED_ACADEMIC_YEAR = 'ADMITTED_ACADEMIC_YEAR',
    DEPARTMENT = "EMP_DEPARTMENT",
    DESIGNATION="EMP_DESIGNATION",
    PURCHASE_ITEM = "PURCHASE_ITEM",
    CAMPAIGN_AREA = "CAMPAIGN_AREA",
    LEAD_SOURCE = "LEAD_SOURCE",
    ENQUIRY_CHANNEL = "ENQUIRY_CHANNEL",
    LEAVE_TYPE = "LEAVE_TYPE",
    INSTITUTE_TYPE = "INSTITUTE_TYPE",
    ACADEMIC_YEAR = "ACADEMIC_YEAR",
    Reserved_Cateory_ind = "Reserved_Cateory_ind",
    BUS_TYPE="BUS_TYPE",
    CLASS_OF_ADMISSION = "CLASS_OF_ADMISSION",
    INWARD_TIME_SLOT = "INWARD_TIME_SLOT",
    OUTWARD_TIME_SLOT = "OUTWARD_TIME_SLOT",
    RELATIONS = "RELATIONS",
}
