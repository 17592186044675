import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService, CommonUtility } from 'projects/common/src/public-api';
import { APIConstant } from '../constant';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  constructor(protected baseService: BaseService) { }

  getCalendarEvents(date: any, isHoliday: any): Observable<any[]> {
    let params = CommonUtility.convertObjectToParams({
      date: date,
      isHoliday: isHoliday
    })
    return this.baseService.get<any[]>(`${APIConstant.eventsByDate}?${params}`);
  }

  getExamsByDate(date: any): Observable<any[]> {
    let params = CommonUtility.convertObjectToParams({
      date: date
    })
    return this.baseService.get<any[]>(`${APIConstant.examScheduleByDate}?${params}`);
  }

  getUpcomingEvents(date: any, isHoliday: any): Observable<any[]> {
    let params = CommonUtility.convertObjectToParams({
      fromDate: date,
      isHoliday: isHoliday
    })
    return this.baseService.get<any[]>(`${APIConstant.upcomingEvents}?${params}`);
  }

  getUpcomingExams(date: any): Observable<any[]> {
    let params = CommonUtility.convertObjectToParams({
      date: date
    })
    return this.baseService.get<any[]>(`${APIConstant.upcomingExams}?${params}`);
  }

  getExamSchedules(examId: any): Observable<any[]> {
    let params = CommonUtility.convertObjectToParams({
      examId: examId
    })
    return this.baseService.get<any[]>(`${APIConstant.examSchedule}?${params}`);
  }

  getWeekOffs(date: any): Observable<any[]> {
    let params = CommonUtility.convertObjectToParams({
      date: date
    })
    if (date) {
      return this.baseService.get<any[]>(`${APIConstant.weekOffs}?${params}`);
    } else {
      return this.baseService.get<any[]>(`${APIConstant.weekOffs}`);
    }
  }

  addEvent(data: any): Observable<any> {
    return this.baseService.post<any>(`${APIConstant.events}`, data);
  }

  updateEvent(data: any): Observable<any> {
    return this.baseService.put<any>(`${APIConstant.events}`, data);
  }

  remove(id: number): Observable<any> {
    return this.baseService.delete<any>(`${APIConstant.events}?calendarEventId=${id}`);
  }

  addCalendarWeekOff(data: any) {
    return this.baseService.post<any>(`${APIConstant.weekOffs}`, data);
  }

  updateCalendarWeekOff(data: any) {
    return this.baseService.put<any>(`${APIConstant.weekOffs}`, data);
  }

  deleteCalendarWeekOff(id: any) {
    return this.baseService.delete<any>(`${APIConstant.weekOffs}?calendarWeekOffId=${id}`);
  }

  importHolidays(data: any) {
    return this.baseService.post<any>(`${APIConstant.importHolidays}`, data);
  }

  getCalendarEventIndicators(fromDate: any, toDate: any) {
    let params = CommonUtility.convertObjectToParams({
      fromDate: fromDate,
      toDate: toDate
    })
    return this.baseService.get<any>(`${APIConstant.eventIndicators}?${params}`);
  }
}
