import { Component, OnInit } from '@angular/core';
import { AppService, RoleService, UserAuthService } from 'projects/common/src/public-api';
import { Router } from '@angular/router';

@Component({
    template: '',
})
export class ValidateComponent implements OnInit {

    constructor(private authService: UserAuthService, 
        private router: Router, 
        private roleService: RoleService,
        private appService: AppService) {
    }

    ngOnInit() {
        this.getToken();
        this.getSecurityGroups();
		this.getColumnConfig();
    }

    private getToken() {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        if (token) {
            window.sessionStorage.setItem('token', token);
            window.sessionStorage.setItem('orgCode', urlParams.get('code'));
            window.sessionStorage.setItem('employeeId', urlParams.get('employeeId'));
            window.sessionStorage.setItem('employeeName', urlParams.get('employeeName'));
            window.sessionStorage.setItem('school', urlParams.get('schoolName'));
            window.sessionStorage.setItem('userName', urlParams.get('userName'));
            setTimeout(() => {
                this.router.navigateByUrl('/dashboard', { preserveQueryParams: false });
            }, 900);
        }
    }
    
    private getColumnConfig() {
        this.appService.datatableSetup('CALENDAR');
      }
    
      private getSecurityGroups() {
        this.roleService.getSecurityGroups('Calendar');
      }
}
